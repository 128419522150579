import React from "react";

const AuthFooter = () => {
  return (
    <footer className="auth-footer">
      <div className="container">
        <div className="footer-options" style={{ justifyContent: "center" }}>
          <p style={{ textAlign: "center" }}>
            © 2024 Joy of others. All Rights Reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default AuthFooter;
